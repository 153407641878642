import React from "react";
import Layout from "../components/Global/layout";

const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <main className="flex flex-row items-center justify-center w-screen h-screen">
          <p classNAme = "text-7xl text-gray-400" >404</p>
        </main>
      </Layout>
    </>
  );
};

export default NotFoundPage;
